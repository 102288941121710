@import "colors";

.material-table {

  margin-bottom: 2%;
  .customPagination {
    margin: 20px auto;
    align-items: center;
    display: flex;
    justify-content: center;

    .MuiPaginationItem-textPrimary.Mui-selected {
      background-color: $accent-color;
    }
  }
}

.light-mode {
  .material-table {
    .MuiToolbar-root {
      color: $accent-color;
    }

    tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    tr:hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
}

.dark-mode {
  #menu- {
    .MuiMenuItem-root {
      color: #fff !important;
    }
  }

  .material-table {
    .MuiTablePagination-toolbar {
      color: #fff !important;

      svg {
        color: #fff !important;
      }

      .MuiTypography-caption {
        color: #fff !important;
      }
    }

    .MuiToolbar-root {
      background-color: #111 !important;
      color: $accent-color;
    }

    .MuiTable-root {
      background-color: #111 !important;
      color: #fff;
    }

    th {
      background-color: #111 !important;
      color: #fff !important;
    }

    th:hover {
      color: $accent-color im !important;
    }

    .MuiButtonBase-root {
      color: #fff !important;
    }

    .MuiButtonBase-root:hover {
      color: $accent-color !important;
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: $accent-color !important;
    }

    .MuiTableSortLabel-icon:hover {
      color: $accent-color !important;
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
      .MuiTableSortLabel-icon {
      color: $accent-color !important;
    }

    .MuiTableCell-body {
      color: #fff;
    }

    .MuiTableCell-root {
      border-bottom: 0px;
    }

    tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    tr:hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
}

@media only screen and (max-width: 700px) {
  .material-table {

    font-size: 80%;

    .MuiToolbar-root {
      flex-direction: row;
      align-items: center;
    }

    #mui-63713 {
      display: none;
    }

    .MuiTablePagination-toolbar {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;

      div, p {
        flex-basis: 40%;
      }

      .MuiTablePagination-spacer {
        flex-basis: 0%;
      }
    }

    .MuiIcon-root {
      font-size: 1.2rem !important;
    }
  }
}
