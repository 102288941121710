.error {
  color: #f44336;
  display: block;
}

.form-input {
  width: 100%;
  margin-bottom: 10px;

  .MuiFormControl-root,
  .MuiInputBase-root {
    width: 100%;
  }
}

.form-buttons {
  display: flex;
  flex-direction: row;
  width: 50%;
  min-width: 200px;

  .wrapper {
    position: relative;

    .buttonProgress {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    }
  }
}

.arabic {
  .display-password {
    margin-right: auto;
  }
}

.english {
  .display-password {
    margin-left: auto;
  }
}

.form-title {
  font-size: 25px !important;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .form-title {
    font-size: 25px !important;
  }
}

.form-description {
  white-space: pre-line !important;
  font-size: 1.3em !important;
}

.section-title {
  font-size: 2em !important;
}

.section-description {
  white-space: pre-line !important;
  font-size: 1.3em !important;
}

.input-label {
  font-size: 1.15em !important;
}