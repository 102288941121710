@import 'colors';

.dark-mode {  
  .footer-box {
    background-color: #111;
    color: #fff;

    a {
      color: $accent-color;
      text-decoration: none;
    }
  }
}

.light-mode {
  a {
    color: $accent-color;
    text-decoration: none;
  }
}
