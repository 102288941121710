.file-upload {
  display: flex;
  flex-direction: row;
  width: 100%;

  .file-names {
    flex-basis: 50%;
  }

  .upload-button {
    margin-left: auto;
  }
}