@media only screen and (max-width: 600px) {
    .profile-photo {
        width: 800% !important;
        max-width: 100px !important;
        object-fit: contain;
    }

    .container {
        flex-direction: column !important;
    }
}

.profile-photo {
    width: 20%;
    margin-left: 45%;
}

.container {
    width: 90%;
    align-self: center;
    white-space: pre-line;
    display: flex;
    flex-direction: row;
    align-items: center;
}