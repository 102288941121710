@import "colors";
body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body.dark-mode {
  background-color: $dark-background-color !important;
  color: #ffffff !important;

  .MuiInput-root {
    color: #fff;

    svg {
      color: #fff;
    }
  }

  .MuiFormLabel-root {
    color: #aaa;
  }

  .MuiMenu-paper {
    background-color: #111;
    color: $accent-color;

    a {
      color: $accent-color;
    }
  }

  .MuiAccordion-root {
    background-color: #111;
    color: $accent-color;
  }

  .MuiInput-underline::before {
    border-bottom: 1px solid #fff;
  }

  .MuiDialog-paper {
    background-color: #050505;
    color: #fff;

    .MuiTypography-colorTextSecondary {
      color: #fff;
    }
  }

  .MuiTabs-root {
    color: $accent-color;
    background-color: #111;
  }

  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #000 !important;
  }
}

body.light-mode {
  .MuiMenu-paper {
    a {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .MuiTabs-root {
    color: #000;
    background-color: #eee;
  }
}

body.light-mode {
  background-color: $light-background-color !important;
  color: #000000 !important;
}

.arabic {
  direction: rtl;

  .MuiListItem-root {
    text-align: right;
  }

  .MuiDialog-paper {
    direction: rtl;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}
