@import "colors";

.collection-filters {
  margin-bottom: 2%;

  .filter-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.dark-mode {
  .collection-filters {
    .MuiAccordionSummary-expandIcon svg {
      color: $accent-color !important;
    }
  }
}