.form-logo {
  width: 14%;
}

@media only screen and (max-width: 600px) {
  .form-logo {
    width: 800% !important;
    max-width: 100px !important;
    object-fit: contain;
  }

  .form-header {
    margin-left: 11% !important;
    font-size: 25px !important;
  }

  .header-container {
    flex-direction: column !important;
  }
}

.form-header {
  font-size: 40px;
  // line-height: 50px;
  font-weight: 600;
}


.header-container {
  width: 90%;
  align-self: center;
  white-space: pre-line;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-font {
  background-color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dark-mode {
  .form-font {
    background-color: black;
  }
}