@import "colors";

.single-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-component .information-table {
  width: 95%;
  max-width: 700px;
  margin-right: auto;
  margin-left: 0%;
}

.single-component .information-table .table-heading {
  text-align: left;
  font-size: 200%;
}

.single-component .action-buttons {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.single-component .action-buttons button {
  margin-left: 10px;
  margin-bottom: 10px;
}

.single-component .array-table {
  width: 95%;
  max-width: 1000px;
  margin: auto;
  margin-top: 5%;
}

.light-mode {
  .single-component {
    .information-table {
      .table-heading {
        background-color: white;
        color: $accent-color;
      }
    }

    .information-table {
      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.1);
      }

      tr:nth-child(odd) {
        background-color: white;
      }
    }
  }

  .single-component .information-table tr:hover,
  .single-component .array-table tr:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

.dark-mode {
  .single-component {
    .information-table {
      td {
        border-bottom: 1px inset black;
        border-top: 1px inset black;
        border-collapse: collapse;
        border-spacing: 0;
      }

      .MuiTableCell-body {
        color: white !important;
      }

      .table-heading {
        color: $accent-color !important;
      }

      tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.9);
        color: white !important;
      }

      tr:nth-child(odd) {
        background-color: rgb(0, 0, 0);
        color: white !important;
      }
    }
  }

  .single-component .information-table tr:hover,
  .single-component .array-table tr:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

.single-component {
  .information-cell {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
}

.arabic {
  .single-component .information-table .table-heading {
    text-align: right !important;
  }

  .single-component .information-table {
    margin-left: auto;
    margin-right: 0%;
  }
}

// .single-component .array-table thead,
// .single-component .array-table th,
// .single-component .array-table .MuiToolbar-regular {
//   background-color: #fff !important;
//   color: #3f51b5 !important;
// }

@media only screen and (max-width: 600px) {
  // body {
  //   background-color: lightblue;
  // }

  .single-component .MuiToolbar-root {
    flex-direction: column;
  }

  .single-component .action-buttons {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}