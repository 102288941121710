@import "colors";

.navbar-username {
  margin-left: auto !important;
}

.dark-mode {
  .nav-bar {
    background-color: #000000 !important;
    color: $accent-color;

    .navbar-buttons {
      color: $accent-color;
    }
  }

  .app-drawer {
    background-color: #111;
    svg,
    span {
      color: $accent-color;
    }
  }
}

.light-mode {
  .nav-bar {
    background-color: $accent-color;
    color: #fff;

    .navbar-buttons {
      color: #fff;
    }
  }

  .app-drawer {
    span {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.arabic {
  .MuiDrawer-paperAnchorLeft {
    left: auto;
    right: 0;
  }

  .makeStyles-appBarShift-3 {
    margin-right: 240px;
    margin-left: 0px;
  }

  .makeStyles-menuButton-4 {
    margin-left: 36px !important;
  }

  .MuiIconButton-edgeStart {
    margin-right: -12px !important;
  }

  .navbar-username {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
}
