.settings-page {

  .settings-list {
    display: flex;
    flex-direction: column;
  }

  .settings-list-item {

    display: flex;
    direction: row;

    .settings-item-label {
      width: 20%;
    }

    .dark-mode-switch {
      display: flex;
      display: row;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .settings-page {
    .settings-list-item {
      .settings-item-label {
        width: 40%;
      }
    }
  }
}